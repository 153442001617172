<template>
    <div class="card fade-in">
        <div class="card-content">
            <span class="card-title" style="font-weight: bold;">Testtermin Buchen</span>
            <p v-html="content"></p>
        </div>
        <div class="card-action" style="display: flex; justify-content: space-between; align-items: center;">
            <p style="font-size: 20px; font-weight: 450;">Zur Buchung</p>
            <a class="btn waves-effect orange accent-4" @click="$emit('started');">Buchung beginnen</a>
        </div>
    </div>
</template>
<script>
export default {
    name: "Start",
    emits: ["started"],
    data() {
        return {
            content: ""
        }
    },
    mounted() {
        this.$loader();
        // Load Content
        this.$db("/test/about").then(data => {
            this.content = data.content;
            this.$loader(false);
        }).catch(() => {
            this.content = "";
            this.$loader(false);
        });
    }
}
</script>