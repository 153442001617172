<template>
    <!-- Login Page -->
    <div class="row fade-in">
        <div class="col s12 m8 l8 push-m2 push-l2">
            <div class="card">
                <div class="card-content">
                    <span class="card-title">Anmeldung</span>
                    <div class="input-field">
                        <input id="name" type="text" v-model="name">
                        <label for="name">Name</label>
                    </div>
                    <div class="input-field">
                        <input id="password" type="password" v-model="password">
                        <label for="password">Passwort</label>
                    </div>
                    <!-- Login Button -->
                    <div class="card-action" style="display: flex; justify-content: flex-end;">
                        <a href="#" class="btn-flat waves-effect waves-light orange-text text-accent-4" @click="login">
                            <i class="material-icons left">arrow_forward</i>
                            Login
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    emits: ["login"],
    data() {
        return {
            name: "",
            password: ""
        }
    },
    methods: {
        login() {
            this.$emit("login", { name: this.name, password: this.password });
        }
    }
}
</script>