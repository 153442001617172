<template>
    <main class="blue-grey lighten-5">
        <navigation-bar></navigation-bar>
        <router-view></router-view>
    </main>
    <footer-bar></footer-bar>
</template>
<script>
/**
 * Imports
 */
import NavigationBar from './components/navigation/';
import FooterBar from './components/footer/';

/**
 * Main App
 */
export default {
    name: 'App',
    components: {
        NavigationBar,
        FooterBar
    },
    watch: {
        $route() {
            // Get Route Title
            let title = this.$route.name;
            // Set Title
            document.title = title;
        }
    }
}
</script>
<style>
body {
    margin: 0;
}
main {
    flex: 1 0 auto;
}
#app {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

strong {
    font-weight: bold !important;
}

/**
    UI LI - add bullet point
*/
ul:not(.collapsible):not(.pagination):not(.select-dropdown):not(.dropdown-content) > li:before {
    content: "•";
    margin-right: 5px;
}
.ql-editor > p > li:before {
    content: "•" !important;
    margin-right: 5px;
}


/* Animations */
.fade-in {
    animation: fade-in 1s forwards;
    animation-name: fade-in;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(.45, .05, .55, .95);
}

.fade-out {
    animation: fade-out 1s forwards;
    animation-name: fade-out forwards;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(.45, .05, .55, .95);
}

@keyframes fade-in {
    0% {
        visibility: hidden;
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        visibility: visible;
        opacity: 1;
    }
    100% {
        visibility: hidden;
        opacity: 0;
    }
}

/* Materialize Inputs */
/* label color */
label {
    color: #3d3d3d !important;
}
/* label focus color */
input[type=text]:focus + label {
    color: #ff6d00 !important;
}
/* label underline focus color */
input[type=text]:focus {
    border-bottom: 1px solid #ff6d00 !important;
    box-shadow: 0 1px 0 0 #ff6d00 !important;
}
/* valid color */
input[type=text].valid {
    border-bottom: 1px solid #ff6d00 !important;
    box-shadow: 0 1px 0 0 #ff6d00 !important;
}
/* invalid color */
input[type=text].invalid {
    border-bottom: 1px solid #e53935 !important;
    box-shadow: 0 1px 0 0 #e53935 !important;
}


/* label focus color */
input[type=email]:focus + label {
    color: #ff6d00 !important;
}
/* label underline focus color */
input[type=email]:focus {
    border-bottom: 1px solid #ff6d00 !important;
    box-shadow: 0 1px 0 0 #ff6d00 !important;
}
/* valid color */
input[type=email].valid {
    border-bottom: 1px solid #ff6d00 !important;
    box-shadow: 0 1px 0 0 #ff6d00 !important;
}
/* invalid color */
input[type=email].invalid {
    border-bottom: 1px solid #e53935 !important;
    box-shadow: 0 1px 0 0 #e53935 !important;
}

/* label focus color */
input[type=number]:focus + label {
    color: #ff6d00 !important;
}
/* label underline focus color */
input[type=number]:focus {
    border-bottom: 1px solid #ff6d00 !important;
    box-shadow: 0 1px 0 0 #ff6d00 !important;
}
/* valid color */
input[type=number].valid {
    border-bottom: 1px solid #ff6d00 !important;
    box-shadow: 0 1px 0 0 #ff6d00 !important;
}
/* invalid color */
input[type=number].invalid {
    border-bottom: 1px solid #e53935 !important;
    box-shadow: 0 1px 0 0 #e53935 !important;
}

/*** Password */
/* label focus color */
input[type=password]:focus + label {
    color: #ff6d00 !important;
}
/* label underline focus color */
input[type=password]:focus {
    border-bottom: 1px solid #ff6d00 !important;
    box-shadow: 0 1px 0 0 #ff6d00 !important;
}
/* valid color */
input[type=password].valid {
    border-bottom: 1px solid #ff6d00 !important;
    box-shadow: 0 1px 0 0 #ff6d00 !important;
}
/* invalid color */
input[type=password].invalid {
    border-bottom: 1px solid #e53935 !important;
    box-shadow: 0 1px 0 0 #e53935 !important;
}

/*** Date */

/*** Password */
/* label focus color */
input[type=date]:focus + label {
    color: #ff6d00 !important;
}
/* label underline focus color */
input[type=date]:focus {
    border-bottom: 1px solid #ff6d00 !important;
    box-shadow: 0 1px 0 0 #ff6d00 !important;
}
/* valid color */
input[type=date].valid {
    border-bottom: 1px solid #ff6d00 !important;
    box-shadow: 0 1px 0 0 #ff6d00 !important;
}
/* invalid color */
input[type=date].invalid {
    border-bottom: 1px solid #e53935 !important;
    box-shadow: 0 1px 0 0 #e53935 !important;
}

ul.dropdown-content.select-dropdown li span {
    color: #3d3d3d; /* no need for !important :) */
}

/* icon prefix focus color */
.prefix.active {
    color: #ff6d00 !important;
}

.prefix.invalid {
    color: #e53935 !important;
}

/**
    Checkbox
*/
.checkbox-orange[type="checkbox"] + label:before{
  border: 2px solid #ff6d00 !important;
  background: transparent;
}
.checkbox-orange[type="checkbox"]:checked + span:before{
  border: 2px solid transparent;
  border-bottom: 2px solid #ff6d00 !important;
  border-right: 2px solid #ff6d00 !important;
  background: transparent;
}
.checkbox-orange.filled-in[type="checkbox"] + label:after{
  border: 2px solid #ff6d00 !important;
  background: transparent;
}
.checkbox-orange.filled-in[type="checkbox"]:checked + label:after{
  background: #ff6d00 !important;
}
.checkbox-orange.filled-in[type="checkbox"]:checked + label:before{
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

/**
    Radio Buttons
*/
[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
    border: 2px solid #ff6d00 !important;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
    background-color: #ff6d00 !important;
}

/*
    Loading Spinner
*/
.background {
    position: fixed;
    z-index: 4999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255,255,255,0.5);

    /** Center Content */
    display: flex;
    justify-content: center;
    align-items: center;
}

.background div {
    opacity: 1;
}

</style>
