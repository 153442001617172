<template>
    <div class="card fade-in">
        <div class="card-content">
            <span class="card-title">Termin Auswählen</span>
            <p>
                <span class="grey-text">
                    Erstellen Sie einen neuen Termin.
                </span>
            </p>
            <!-- Day Selection -->
            <div class="divider"></div>
            <form>
                <br>
                <div class="row">
                    <div class="input-field col s12">
                        <i class="material-icons prefix">date_range</i>
                        <input type="text" class="datepicker" placeholder="Terminauswahl">
                        <label>Terminauswahl</label>
                    </div>
                </div>
            </form>
            <!-- Reload Button -->
            <div class="row">
                <div class="col s12">
                    <a class="btn-flat waves-effect waves-light orange-text text-accent-4 right" @click="update">
                        <i class="material-icons left">refresh</i>
                        Terminauswahl neu Laden
                    </a>
                </div>
            </div>
            <!-- Time Selection -->
            <div class="divider"></div>
            <table>
                <thead>
                    <tr>
                        <th>Uhrzeit</th>
                        <th>Verfügbare Plätze</th>
                        <th>Buchen</th>
                    </tr>
                </thead>
                <tbody>
                    <cell
                        v-for="slot in slots"
                        :key="slot._id"
                        :timeslot="slot"
                        @selected="selected"
                    ></cell>
                    <tr v-if="!slotsAvailable()">
                        <td colspan="3">
                            <p class="center-align">
                                <i class="material-icons">sentiment_dissatisfied</i>
                                <br>
                                <br>
                                Keine Termine verfügbar.
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
// Import materializecss
import M from "materialize-css";
import Cell from "./cell";

export default {
    name: "Calendar",
    components: {
        Cell
    },
    props: {
        calendar: {
            type: Object,
            required: true
        }
    },
    emits: ["select", "refresh"],
    data() {
        return {
            day: new Date().getDay(),
            slots: []
        }
    },
    watch: {
        calendar() {
            this.setSlots();
        }
    },
    mounted() {
        // Set Slots
        this.setSlots();

        // Get Now
        let now = new Date();

        // If no slots are available, go to the next day
        if (this.slots.length === 0 || !this.slotsAvailable()) {
            this.day++;
            if(this.day > 6) this.day = 0; // Reset Day if > saturday
            // Recalc Slots
            this.setSlots();

            // Add one day to the date
            now.setDate(now.getDate() + 1);
        }

        // Reset Slots
        this.slots = [];

        // Initialize datepicker
        const elems = document.querySelectorAll('.datepicker');
        M.Datepicker.init(elems, {
            autoClose: true,
            defaultDate: now,
            setDefaultDate: true,
            minDate: new Date(),
            maxDate: new Date(new Date().getTime() + (6 * 24 * 60 * 60 * 1000)),
            onSelect: this.dateSelect,
            disableDayFn: (date) => {
                /**
                 * Disable Day if there are no slots available
                 */

                // Get Current Day
                let day = date.getDay();

                // Get Slots for the day
                const slots = this.calendar.slots.filter(slot => slot.meta.day === day);

                // Check if there are slots available
                const size = slots.filter(slot => {
                    return (slot.capacity - slot.used > 0 && this.slotToTimestamp(slot) > new Date().getTime());
                }).length;

                if(size > 0) return false;
                return true;
            },
            format: "dd. mmmm, yyyy",
            i18n: {
                cancel: "Abbrechen",
                done: "Fertig",
                months: [
                    "Januar",
                    "Februar",
                    "März",
                    "April",
                    "Mai",
                    "Juni",
                    "Juli",
                    "August",
                    "September",
                    "Oktober",
                    "November",
                    "Dezember"
                ],
                monthsShort: [
                    "Jan",
                    "Feb",
                    "Mär",
                    "Apr",
                    "Mai",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Okt",
                    "Nov",
                    "Dez"
                ],
                weekdays: [
                    "Sonntag",
                    "Montag",
                    "Dienstag",
                    "Mittwoch",
                    "Donnerstag",
                    "Freitag",
                    "Samstag"
                ],
                weekdaysShort: [
                    "So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"
                ],
                weekdaysAbbrev: ["So","Mo","Di","Mi","Do","Fr","Sa"]
            }
        });
    },
    methods: {
        selected($event) {
            this.$emit("select", $event);
        },
        dateSelect($event) {
            if(!$event) return;
            this.day = $event.getDay();
            this.setSlots();
        },
        setSlots() {
            this.slots = this.calendar.slots.filter(slot => slot.meta.day === this.day);
        },
        update() {
            this.$emit("refresh");
        },
        slotsAvailable() {
            return this.slots.filter(slot => {
                return (slot.capacity - slot.used > 0 && this.slotToTimestamp(slot) > new Date().getTime());
            }).length;
        },
        slotToTimestamp(slot) {
            // Get Current Date
            const date = new Date();
            const resultDate = new Date(date.getTime());

            // Set Hour and Minute
            resultDate.setHours(slot.meta.hour);
            resultDate.setMinutes(slot.meta.minute);

            // Remove Milliseconds & Seconds
            resultDate.setSeconds(0);
            resultDate.setMilliseconds(0);

            // Get Next Date
            resultDate.setDate(date.getDate() + (7 + slot.meta.day - date.getDay()) % 7);

            // Return
            return resultDate.getTime();
        }
    }
}
</script>
<style scoped>
.table td,tr>td,tr>th {
    text-align: center;
}
</style>