<template>
    <!-- Calendar Editor -->
    <div class="row">
        <div class="col s12 m12 l12">
            <!-- Collapsible -->
            <ul class="collapsible">
                <li>
                    <div class="collapsible-header"><i class="material-icons">date_range</i>Kalender für {{ type }}</div>
                    <div class="collapsible-body white" style="overflow: auto;">
                        <!-- Generate Table for each day -->
                        <table class="striped">
                            <thead>
                                <tr>
                                    <th>Uhrzeit</th>
                                    <th>Sonntag</th>
                                    <th>Montag</th>
                                    <th>Dienstag</th>
                                    <th>Mittwoch</th>
                                    <th>Donnerstag</th>
                                    <th>Freitag</th>
                                    <th>Samstag</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Loop Slot Count -->
                                <tr v-for="index in getIndex()" :key="index">
                                    <!-- Loop Times -->
                                    <td>
                                        <!-- Time Slot -->
                                        <b>{{ getSlot(1, index).meta.hour.toString().padStart(2,0) }}:{{ getSlot(1, index).meta.minute.toString().padStart(2,0) }}</b>
                                        &nbsp;
                                        <!-- Row Edit Button -->
                                        <button class="btn-floating waves-effect waves-light orange accent-4" @click="editRow(index)"><i class="material-icons">edit</i></button>
                                    </td>
                                    <!-- Loop Days -->
                                    <td v-for="day in 7" :key="day">
                                        <!-- Slot Capacity -->
                                        <input style="width: 100px; text-align: center;" type="text" :value="getSlot(day, index).capacity" @change="editSlot(getSlot(day, index), $event.target.value)">
                                    </td>
                                </tr>
                                <!-- Row Edit Button -->
                                <tr>
                                    <td></td>
                                    <td v-for="day in 7" :key="day">
                                        <button class="btn-floating waves-effect waves-light orange accent-4" @click="editColumn(day)"><i class="material-icons">edit</i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="divider"></div>
                        <div style="display: flex; align-items: center; justify-content: center; flex-wrap: wrap; width: 100%; margin-top: 10px;">
                            <!-- Pagination -->
                            <ul class="pagination" style="display: flex; justify-content: center; flex-wrap: wrap;">
                                <li><a href="#!" @click="$event.preventDefault(); prevPage();"><i class="material-icons">chevron_left</i></a></li>
                                <li
                                    v-for="index in Math.floor(slotCount / pagination.size)"
                                    :key="index"
                                    :class="(index - 1) * pagination.size == pagination.start ? 'active orange accent-4' : ''"
                                >
                                    <a href="#" @click="$event.preventDefault(); setPage(index - 1);">{{ index - 1}}h</a>
                                </li>
                                <li class="waves-effect"><a href="#!" @click="$event.preventDefault(); nextPage();"><i class="material-icons">chevron_right</i></a></li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import M from "materialize-css";

export default {
    emits: ["refresh"],
    props: {
        type: {
            type: String,
            required: true
        },
        calendar: {
            type: Array,
            required: true
        },
        jwt: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            slotCount: 0,
            pagination: {
                start: 0,
                size: 4
            }
        }
    },
    mounted() {
        // Count slots for each day in meta.day for sunday
        this.calendar.forEach(slot => {
            if (slot.meta.day == 0) {
                this.slotCount++;
            }
        });

        // M.AutoInit();
        M.AutoInit();
    },
    methods: {
        setPage(index) {
            this.pagination.start = index * this.pagination.size;
        },
        nextPage() {
            this.pagination.start += this.pagination.size;
            if(this.pagination.start >= this.slotCount) {
                this.pagination.start = 0;
            }
        },
        prevPage() {
            this.pagination.start -= this.pagination.size;
            if(this.pagination.start < 0) {
                this.pagination.start = this.slotCount - this.pagination.size;
            }
        },
        getIndex() {
            // Create a new array, starting by 1 ending at pagination.size
            let array = new Array(this.pagination.size);
            for (let i = 1; i < this.pagination.size + 1; i++) {
                array[i] = i;
            }

            // increase index by pagination.start
            array = array.map(index => index + this.pagination.start);

            // Remove entry >= slotCount
            array = array.filter(index => index <= this.slotCount);
            return array;
        },
        getSlot(day, index) {
            return this.calendar.filter(slot => slot.meta.day === day - 1)[index-1];
        },
        editSlot(slot, capacity) {
            // If capacity is not a number, return
            if(isNaN(capacity)) {
                M.toast({ html: "Bitte eine Zahl eingeben", classes: "red darken-4" });
                return;
            }

            capacity = parseInt(capacity);

            // DB Request
            this.$db("/admin/calendar", {
                token: this.jwt,
                id: slot._id,
                capacity: capacity
            }, "PATCH").then(() => {
                // Refresh Data
                this.$emit("refresh");
            }).catch(err => {
                M.toast({ html: err.error, classes: "red darken-4" });
            });
        },
        editRow(index) {
            // Open Prompt for editing
            let value = prompt("Neue Kapazität für diese Zeile eingeben.", 0);

            // Parse Value to Number
            value = parseInt(value);

            // Check
            if(isNaN(value)) {
                M.toast({ html: "Bitte eine Zahl eingeben", classes: "red darken-4" });
                return;
            }

            // Set Loader
            this.$loader(true);

            // Get every slot from 0 to 6 on index & edit it
            for(let day = 1; day <= 7; day++) {
                this.editSlot(this.getSlot(day, index), value);
            }

            // Reset Loader
            this.$loader(false);
        },
        editColumn(day) {
            // Open Prompt for editing
            let value = prompt("Neue Kapazität für diese Zeile eingeben.", 0);

            // Parse Value to Number
            value = parseInt(value);

            // Check
            if(isNaN(value)) {
                M.toast({ html: "Bitte eine Zahl eingeben", classes: "red darken-4" });
                return;
            }

            // Set Loader
            this.$loader(true);

            // Loop Index
            this.getIndex().forEach(index => {
                // Edit Slot
                this.editSlot(this.getSlot(day, index), value);
            });

            // Reset Loader
            this.$loader(false);
        }
    }
}
</script>
<style scoped>
th, td {
    text-align: center;
}
</style>