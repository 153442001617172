<template>
    <div class="card fade-in">
        <div class="card-content">
            <span class="card-title">Ihre Daten Eingeben</span>
            <p>
                <span class="grey-text">
                    Bitte geben Sie Ihre persönlichen Daten ein.
                </span>
            </p>
            <div class="divider"></div>
            <form style="margin-top: 10px;">
                <!-- Impfstoff auswahl -->
                <div v-if="$route.params.type === 'impfung'">
                    <p style="margin-top: 25px;"><b>Termin Art</b></p>
                    <div class="divider"></div>
                    <div class="row">
                        <div class="input-field col s12">
                            <select
                                id="impfstoff"
                                ref="impfstoff"
                                :class="other.selection ? '' : 'invalid'"
                                @change="select"
                            >
                                <option value="" disabled selected hidden>-- auswählen --</option>
                                <!-- Loop Vaccines -->
                                <option v-for="vaccine in vaccines" :key="vaccine._id" :value="vaccine.name" v-text="vaccine.name"></option>
                            </select>
                            <label for="impfstoff">Impfstoff Auswählen</label>
                            <span class="helper-text" data-error="Impfstoff auswählen"></span>
                        </div>
                    </div>
                </div>
                <!-- Corona Warn App Test Registrierung -->
                <div v-else>
                    <p style="margin-top: 25px;"><b>Corona Warn-App Test Registrierung</b></p>
                    <div class="divider"></div>
                    <div class="row">
                        <div class="input-field col s12">
                            <p>
                                <label>
                                    <input name="coronaApp" class="with-gap" type="radio" value="Keine Übermittlung" checked v-model="other.selection"/>
                                    <span style="width: 100%;"><b>Keine Übermittlung</b></span>
                                </label>
                            </p>
                            <p>
                                <label>
                                    <input name="coronaApp" class="with-gap" type="radio" value="Pseudonymisierte Übermittlung" v-model="other.selection"/>
                                    <span style="width: 100%;">Einwilligung zur <b>PSEUDONYMISIERTEN ÜBERMITTLUNG</b><br>(Nicht-Namentlich)</span>
                                </label>
                            </p>
                            <p style="width: 100%; text-align: center;">- oder -</p>
                            <p>
                                <label>
                                    <input name="coronaApp" class="with-gap" type="radio" value="Namentlicher Testnachweis" v-model="other.selection" />
                                    <span style="width: 100%;">Einwilligung zur <b>PERSONALISIERTEN ÜBERMITTLUNG</b><br>(Namentlicher Testnachweis)</span>
                                </label>
                            </p>
                        </div>
                        <div class="col s12 divider" style="margin-bottom: 10px;"></div>
                        <div class="col s12 grey-text text-darken-2">
                            <p v-if="other.selection == 'Keine Einwilligung'">
                                Keine Übermittlung meines Testergebnisses an die Corona Warn-App.
                            </p>
                            <p v-if="other.selection == 'Pseudonymisierte Übermittlung'">
                                "Hiermit erkläre ich mein Einverständnis zum Übermitteln meines Testergebnisses und meines pseudonymen Codes an das Serversystem des RKI, damit ich mein Testergebnis mit der Corona-Warn-App abrufen kann. Das Testergebnis in der App kann hierbei nicht als namentlicher Nachweis verwendet werden. Mir wurden Hinweise zum Datenschutz ausgehändigt." <a href="https://impf-und-testzentrum-iserlohn.de/datenschutz/" target="_blank">(Hinweise zum Datenschutz)</a>
                            </p>
                            <p v-if="other.selection == 'Namentlicher Testnachweis'">
                                "Hiermit erkläre ich mein Einverständnis zum Übermitteln des Testergebnisses und meines pseudonymen Codes an das Serversystem des RKI, damit ich mein Testergebnis mit der Corona-Warn-App abrufen kann. Ich willige außerdem in die Übermittlung meines Namens und Geburtsdatums an die App ein, damit mein Testergebnis in der App als namentlicher Testnachweis angezeigt werden kann. Mir wurden Hinweise zum Datenschutz ausgehändigt". <a href="https://impf-und-testzentrum-iserlohn.de/datenschutz/" target="_blank">(Hinweise zum Datenschutz)</a>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Personal Data -->
                <p style="margin-top: 25px;"><b>Persönliche Daten</b></p>
                <div class="divider"></div>
                <div class="row">
                    <div class="input-field col s6">
                        <input type="text" id="firstName" :class="patient.firstName.length && patient.firstName.length <= 50 ? '' : 'invalid'" :value="patient.firstName" @input="patient.firstName = $event.target.value" />
                        <label for="firstName">Vorname</label>
                        <span class="helper-text" data-error="Ungültiger Vorname"></span>
                    </div>
                    <div class="input-field col s6">
                        <input type="text" id="lastName" :class="patient.lastName.length && patient.lastName.length <= 50 ? '' : 'invalid'" :value="patient.lastName" @input="patient.lastName = $event.target.value" />
                        <label for="lastName">Nachname</label>
                        <span class="helper-text" data-error="Ungültiger Nachname"></span>
                    </div>
                    <!-- Picker for Desktop -->
                    <div v-if="windowWidth > 600" class="input-field col s12" style="margin-top: 20px;">
                        <i class="material-icons prefix">date_range</i>
                        <input type="date" id="birthdate" :class="patient.birthdate.length && patient.birthdate.length <= 10  ? '' : 'invalid'" :max="getToday()" @change="parseDateInput">
                        <label for="birthdate">Geburtsdatum</label>
                        <span class="helper-text" data-error="Ungültiges Geburtsdatum (Bsp: 01.01.1970)"></span>
                    </div>
                    <!-- Input Text for Mobile -->
                    <div v-else class="input-field col s12" style="margin-top: 20px;">
                        <i class="material-icons prefix">date_range</i>
                        <input type="text" id="birthdate" :class="patient.birthdate.length && patient.birthdate.length <= 10  ? '' : 'invalid'" @change="parseDateText">
                        <label for="birthdate">Geburtsdatum</label>
                        <span class="helper-text" data-error="Ungültiges Geburtsdatum (Bsp: 01.01.1970)"></span>
                    </div>
                </div>
                <!-- Contact Info -->
                <p style="margin-top: 15px;"><b>Kontaktdaten</b></p>
                <div class="divider"></div>
                <div class="row">
                    <div class="input-field col s12">
                        <input type="email" id="email" :class="validateEmail(patient.email) && patient.email.length <= 150  ? '' : 'invalid'" :value="patient.email" @input="patient.email = $event.target.value" />
                        <label for="email">E-Mail</label>
                        <span class="helper-text" data-error="Ungültige Mail-Adresse"></span>
                    </div>
                    <div class="input-field col s12">
                        <input type="text" id="phone" :class="validatePhone(patient.phone) && patient.phone.length && patient.phone.length <= 50  ? '' : 'invalid'" :value="patient.phone" @input="patient.phone = $event.target.value" />
                        <label for="phone">Telefonnummer</label>
                        <span class="helper-text" data-error="Ungültig (+49/0 Vorwahl Rufnummer)">+49/0 Vorwahl Rufnummer</span>
                    </div>
                </div>
                <!-- Adress Info -->
                <p style="margin-top: 15px;"><b>Anschrift</b></p>
                <div class="divider"></div>
                <div class="row">
                    <div class="input-field col s8">
                        <input type="text" id="street" :class="patient.street.length && patient.street.length <= 150  ? '' : 'invalid'" :value="patient.street" @input="patient.street = $event.target.value" />
                        <label for="street">Straße</label>
                        <span class="helper-text" data-error="Ungültiger Straßenname"></span>
                    </div>
                    <div class="input-field col s4">
                        <input type="text" id="streetNumber" :class="patient.streetNumber.length && patient.streetNumber.length <= 50  ? '' : 'invalid'" :value="patient.streetNumber" @input="patient.streetNumber = $event.target.value" />
                        <label for="streetNumber">Hausnummer</label>
                        <span class="helper-text" data-error="Ungültige Hausnummer"></span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s4">
                        <input type="text" id="zip" :class="isNaN(patient.zip) || patient.zip.length !== 5 ? 'invalid' : ''" :value="patient.zip" @input="patient.zip = $event.target.value || ''" />
                        <label for="zip">PLZ</label>
                        <span class="helper-text" data-error="Ungültige Postleitzahl"></span>
                    </div>
                    <div class="input-field col s8">
                        <input type="text" id="city" :class="patient.city.length && patient.city.length <= 150  ? '' : 'invalid'" :value="patient.city" @input="patient.city = $event.target.value" />
                        <label for="city">Stadt</label>
                        <span class="helper-text" data-error="Ungültige Stadt"></span>
                    </div>
                </div>
            </form>
            <!-- AGB & Datenschutz -->
            <div>
                <p style="margin-top: 15px;"><b>Allgemeines</b></p>
                <div class="divider" style="margin-bottom: 5px;"></div>
                <p style="margin-bottom: 5px;">Hiermit bestätige ich, dass...</p>
                <p>
                    <label>
                        <input type="checkbox" class="checkbox-orange" :checked="checkboxes.datenschutz" @change="checkboxes.datenschutz = $event.target.checked"/>
                        <span>ich die <a href="https://impf-und-testzentrum-iserlohn.de/datenschutz/" target="_blank">Datenschutzvereinbarung</a> gelesen und akzeptiert habe.</span>
                    </label>
                </p>
                <p>
                    <label>
                        <input type="checkbox" class="checkbox-orange"  :checked="checkboxes.verarbeitung" @change="checkboxes.verarbeitung = $event.target.checked"/>
                        <span>ich der Verarbeitung meiner Personenbezogenen Daten durch das Impfzentrum zustimme.</span>
                    </label>
                </p>
                <p>
                    <label>
                        <input type="checkbox" class="checkbox-orange"  :checked="checkboxes.email" @change="checkboxes.email = $event.target.checked"/>
                        <span>ich per E-Mail kontaktiert werden darf.</span>
                    </label>
                </p>
            </div>
        </div>
        <div class="divider"></div>
        <!-- Submit Button -->
        <div class="card-content" style="display: flex; align-items: center; justify-content: flex-end;">
            <button class="btn waves-effect waves-light orange accent-4" :disabled="!checkAllFields()" @click="submit">Anlegen</button>
        </div>
    </div>
</template>
<script>
// Import materializecss
import M from "materialize-css";

export default {
    name: "Patient",
    props: {
        template: {
            type: Object,
            required: true
        },
        vaccines: {
            type: Array,
            required: true,
        }
    },
    emits: ["submit"],
    data() {
        return {
            patient: {
                firstName: "",
                lastName: "",
                birthdate: new Date().toLocaleDateString(),
                email: "",
                phone: "",
                street: "",
                streetNumber: "",
                city: "",
                zip: ""
            },
            other: {
                selection: null
            },
            checkboxes: {
                datenschutz: false,
                verarbeitung: false,
                email: false
            },
            windowWidth: window.innerWidth,
            material: {
                select: null,
            }
        }
    },
    watch: {
        "other.selection"() {
            if(!this.material.select) return;

            // if selection is null or length is 0, add "invalid" to classes
            if(!this.other.selection || this.other.selection.length === 0) {
                this.material.select.wrapper.classList.add("invalid");
            } else {
                this.material.select.wrapper.classList.remove("invalid");
            }
        }
    },
    mounted() {
        // Init Values
        this.patient = this.template.patient;
        this.other = this.template.other;

        // Init Materialize
        M.AutoInit();

        // Get Materialize Select
        if(this.$refs.impfstoff) {
            this.material.select = M.FormSelect.getInstance(this.$refs.impfstoff);
            // Add invalid class
            this.material.select.wrapper.classList.add("invalid");
        }

        // Listen to resize event
        window.addEventListener("resize", () => {
            this.windowWidth = window.innerWidth;
        });
    },
    beforeUnmount() {
        // Remove Listeners
        window.removeEventListener("resize", () => {
            this.windowWidth = window.innerWidth;
        });
    },
    methods: {
        getToday() {
            // Get today
            const today = new Date().toLocaleDateString();
            // Format to yyyy-mm-dd, padstart month and day to two digits
            const parts = today.split(".").map(x => x.padStart(2, "0"));
            // Return formatted date
            return `${parts[2]}-${parts[1]}-${parts[0]}`;
        },
        select(event) {
            this.other.selection = event.target.value;
        },
        submit() {
            this.$emit("submit", { patient: this.patient, other: this.other });
        },
        parseDateInput($event) {
            // Get Value
            let value = $event.target.value;

            if(!value.length) {
                return this.patient.birthdate = '';
            }

            // Value to Date
            let date = new Date(value);

            // Parse Date
            date = this.$moment(date, "DD.MM.YYYY").toDate();

            // Set Target Value
            this.patient.birthdate = date.toLocaleDateString("de-DE");
        },
        parseDateText($event) {
            // Get Value
            let value = $event.target.value;

            if(!value.length) {
                return this.patient.birthdate = '';
            }

            // Extract Day, Month and Year
            let parts = value.split(".");

            // Check Parts
            if(parts.length !== 3) {
                return this.patient.birthdate = '';
            }

            // Convert to date with moment.js
            let date = this.$moment(value, "DD.MM.YYYY");

            // Check if date is valid
            if(!date.isValid()) {
                return this.patient.birthdate = '';
            }

            // Return
            this.patient.birthdate = date.toDate().toLocaleDateString("de-DE");
        },
        checkAllFields() {
            // Check if every field is filled
            if (this.patient.firstName && this.patient.lastName && this.patient.birthdate && this.patient.email && this.patient.phone && this.patient.street && this.patient.streetNumber && this.patient.city && this.patient.zip) {
                // Check if selection is set
                if(!this.other.selection) {
                    return false;
                }

                // Check if Mail is valid with regex
                if(!this.validateEmail(this.patient.email)) {
                    return false;
                }

                // Check if zip is in range
                if(this.patient.zip.length !== 5) {
                    return false;
                }

                // Check if all checkboxes are checked
                if(!this.checkboxes.datenschutz || !this.checkboxes.verarbeitung || !this.checkboxes.email) {
                    return false;
                }

                return true;
            } else {
                return false;
            }
        },
        validateEmail(email) {
            return String(email)
                .toLowerCase()
                .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        validatePhone(phone) {
            let passes = true;
            // Three Checks

            // Check for valid phone
            const phoneRegex = /(?:\(\+?\d+\)|\+?\d+)(?:\s*[-/]*\s*\d+)+/gm;
            if (phoneRegex.test(phone) === false) {
                passes = false;
            }

            // CHeck if Phone only contains numbers, spaces, dashes and plus
            if (phone.match(/[^0-9\s\-+]/g)) {
                passes = false;
            }

            // Check phone only contains one + or not
            if (phone.includes("+") && phone.match(/\+/g).length > 1) {
                passes = false;
            }

            // True
            return passes;
        }
    }
}
</script>