<template>
    <div class="container">
        <div class="row">
            <div class="col s12 m10 l6 push-m1 push-l3">
                <!-- Go to Impfung -->
                <div class="card fade-in white">
                    <div class="card-content">
                        <span class="card-title">Impftermin Buchen</span>
                        Um einen Impftermin zu buchen, bitte auf den Link klicken.
                    </div>
                    <div class="card-action right-align">
                        <!-- Router Link -->
                        <router-link class="btn-flat waves-effect waves-light orange-text text-accent-4" to="/appointment/impfung">
                            <i class="material-icons left">arrow_forward</i>
                            Impfung buchen
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col s12 m10 l6 push-m1 push-l3">
                <!-- Go to Test -->
                <div class="card fade-in white">
                    <div class="card-content">
                        <span class="card-title">Testtermin Buchen</span>
                        Um einen Testtermin zu buchen, bitte auf den Link klicken.
                    </div>
                    <div class="card-action right-align">
                        <!-- Router Link -->
                        <router-link class="btn-flat waves-effect waves-light orange-text text-accent-4" to="/appointment/test">
                            <i class="material-icons left">arrow_forward</i>
                            Test buchen
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>