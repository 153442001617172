<template>
    <!-- Admin Panel -->
    <div class="container">
        <div v-if="loggedIn">
            <!-- Vaccines -->
            <vaccines :vaccines="vaccines" :jwt="jwt" @refresh="loadData()"></vaccines>
            <!-- About Pages -->
            <editor type="impfung" :jwt="jwt" @refresh="loadData()"></editor>
            <editor type="test" :jwt="jwt" @refresh="loadData()"></editor>
            <!-- Calendar -->
            <calendar type="Impfung" :calendar="calendar['impfung']" :jwt="jwt" @refresh="loadData()"></calendar>
            <calendar type="Test" :calendar="calendar['test']" :jwt="jwt" @refresh="loadData()"></calendar>
        </div>
        <login-screen v-else @login="login"/>
    </div>
</template>
<script>
import M from "materialize-css";

// Components
import loginScreen from "./components/login.vue";
import calendar from "./components/calendar.vue";
import vaccines from "./components/vaccines.vue";
import editor from "./components/editor.vue";

export default {
    components: {
        loginScreen, vaccines, calendar, editor
    },
    data() {
        return {
            jwt: null,
            loggedIn: false,
            calendar: {
                impfung: [],
                test: []
            },
            vaccines: []
        }
    },
    mounted() {
        // Load Data
        this.loadData();
    },
    methods: {
        async loadData() {
            // Loop Calendar Types
            Object.keys(this.calendar).forEach(async key => {
                // Get Calendar
                const { slots } = await this.$db(`/${key}`);
                this.calendar[key] = slots;
            });

            // Get Vaccines
            this.vaccines = await this.$db("/vaccine");
        },
        async login(userdata) {
            // Loader
            this.$loader();

            // Request API
            this.$db("/me", { name: userdata.name, password: userdata.password }, "POST").then(data => {
                this.jwt = data;
                this.loggedIn = true;
                this.$loader(false);
            }).catch(err => {
                M.toast({ html: err.error, classes: "red darken-4" });
                this.$loader(false);
            });
        }
    }
}
</script>
