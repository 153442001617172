<template>
    <!-- Vaccine Editor -->
    <div class="row">
        <div class="col s12 m12 l12">
            <!-- Collapsible -->
            <ul class="collapsible">
                <li>
                    <div class="collapsible-header"><i class="material-icons">colorize</i>Startseite {{ capitalizeFirstLetter(type) }}</div>
                    <div class="collapsible-body white">
                        <div class="row">
                            <div class="col s12">
                                <!-- Editor -->
                                <VueEditor ref="editor" v-model="content"></VueEditor>
                                <!-- Submit -->
                                <div class="card-action" style="display: flex; justify-content: flex-end;">
                                    <a href="#" class="btn-flat waves-effect waves-light orange-text text-accent-4" @click="save">
                                        <i class="material-icons left">save</i>
                                        Speichern
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import M from "materialize-css";
import { VueEditor } from "vue3-editor";

export default {
    components: { VueEditor },
    emits: ["refresh"],
    props: {
        type: {
            type: String,
            required: true
        },
        jwt: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            content: "",
            toolBarConfig: [[{ header: [1, 2, 3, false] }], [{ color: [] }, { background: [] }], ['bold', 'italic', 'underline', 'strike'], [{ align: [] }], ['clean']]
        }
    },
    mounted() {
        // Get Collapsibles
        const collapsibles = document.querySelectorAll(".collapsible");
        // Init materialize
        M.Collapsible.init(collapsibles);

        // Get Editor
        const editor = this.$refs.editor.$el;

        editor.getElementsByTagName("select").forEach(select => {
            select.classList.add("browser-default");
        })

        // Load Content
        this.$db(`${this.type}/about`).then(data => {
            this.content = data.content;
        }).catch(() => {
            this.content = "";
        });
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        save() {
            // Show Loader
            this.$loader();
            // Post Content
            this.$db(`/${this.type}/about`, { content: this.content, token: this.jwt }, "POST").then(data => {
                // Hide Loader
                this.$loader(false);
                // Show Toast
                M.toast({ html: data, classes: "green darken-2" });
            }).catch(err => {
                // Hide Loader
                this.$loader(false);
                // Show Toast
                M.toast({ html: err.error, classes: "red darken-4" });
            });
        }
    }
}
</script>
