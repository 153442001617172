/**
 * Imports
 */
import { createWebHistory, createRouter } from "vue-router";

/**
 * Components
 */
import home from "../../components/home/";
import admin from "../../components/admin/";
import appointment from "../../components/appointment/";
import verify from "../../components/verify/";
import notFound from "../../components/utils/404.vue";

/**
 * Routes
 */
const routes = [{
        component: home,
        name: "Startseite",
        path: "/"
    },
    {
        component: appointment,
        name: "Termin Buchen",
        path: "/appointment/:type"
    },
    {
        component: verify,
        name: "Termin Verifizieren",
        path: "/verify/:code"
    },
    {
        component: admin,
        name: "Admin Panel",
        path: "/admin",
    },
    // Not found
    {
        component: notFound,
        name: "404",
        path: '/:pathMatch(.*)*'
    }
]

// Create Router
const router = createRouter({
    history: createWebHistory(),
    routes,
});

/**
 * Router
 */
export default (app) => {
    // Use Router
    app.use(router);
};