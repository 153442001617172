<template>
    <!-- Appointment Overview -->
    <div class="row fade-in">
        <div class="col s12 m10 l6 push-m1 push-l3">
            <div class="card">
                <div class="card-content">
                    <span class="card-title" style="display: flex; align-items: center; justify-content: space-between;">
                        Ihre Termine
                        <!-- Delete Button -->
                        <a href="#" title="Terminspeicher löschen" class="btn-floating waves-effect waves-light orange accent-4" @click="resetAppointments">
                            <i title="Terminspeicher löschen" class="material-icons" style="line-height: 40px;">delete</i>
                        </a>
                    </span>
                    <p v-if="!appointments.length">
                        <span class="grey-text">
                            Sie haben noch keine Termine angelegt.
                            <br>
                        </span>
                    </p>
                    <p v-else>
                        <span
                            v-for="appointment in appointments"
                            :key="appointment"
                            class="grey-text"
                        >
                            {{ new Date(appointment.timestamp).toLocaleString() }} - für {{ `${appointment.patient.firstName} ${appointment.patient.lastName}` }}{{ appointment.other?.selected?.length ? ` mit ${appointment.other?.selected}` : '' }}
                            <br>
                        </span>
                        <br>
                    </p>
                    <p
                        role="alert"
                        class="grey-text"
                    >
                        <br>
                        <i class="material-icons left">warning</i> Wichtig:
                        Sie müssen Ihre Termine über die an Sie gesendeten E-Mails bestätigen.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- Appointment is over -->
    <div v-if="outdated" class="row fade-in">
        <div class="col s12 m10 l6 push-m1 push-l3" style="margin-top: -20px;">
            <div class="card red darken-4">
                <div class="card-content">
                    <span class="card-title white-text" style="display: flex; align-items: center; font-weight: bolder;">
                        <i class="material-icons orange-text text-lighten-2" style="font-size: 35px; margin-top: 5px;">warning</i>
                        Wichtig
                    </span>
                    <p>
                        <span class="white-text text-darken-1">
                            Ihr gewählter Termin liegt bereits in der Vergangenheit. Eine Buchung ist nicht mehr möglich.
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- Slot is near over -->
    <div v-if="nearOutdated && !outdated" class="row fade-in">
        <div class="col s12 m10 l6 push-m1 push-l3" style="margin-top: -20px;">
            <div class="card orange darken-4">
                <div class="card-content">
                    <span class="card-title white-text" style="display: flex; align-items: center; font-weight: bolder;">
                        <i class="material-icons orange-text text-lighten-2" style="font-size: 35px; margin-top: 5px;">warning</i>
                        Achtung
                    </span>
                    <p>
                        <span class="white-text text-darken-1">
                            Ihr gewählter Termin beginnt innerhalb der nächsten 5 Minuten. Eine Buchung nach dem Zeitpunkt des Termins ist nicht mehr möglich.
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- New Appointment -->
    <div v-if="!wizard" class="row fade-in">
        <!-- Start Wizard Box -->
        <div class="col s12 m10 l6 push-m1 push-l3" style="margin-top: -20px;">
            <div class="card">
                <div class="card-content">
                    <span class="card-title">Neuer Termin</span>
                    <p>
                        <span class="grey-text">
                            Erstellen Sie einen neuen Termin.
                        </span>
                    </p>
                </div>
                <div class="card-action">
                    <a href="#" class="btn-flat waves-effect waves-light orange-text text-accent-4" @click="createNew();">
                        <i class="material-icons left">date_range</i>
                        Termin anlegen
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="row">
            <!-- Back Button -->
            <div v-if="stage > 0" class="col s12 m10 l6 push-m1 push-l3" style="margin-top: -20px;">
                <div class="card fade-in">
                    <div class="card-action">
                        <a href="#" class="btn-flat waves-effect waves-light orange-text text-accent-4" @click="stage--;">
                            <i class="material-icons left">arrow_back</i>
                            Zurück
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- Calendar -->
            <div v-if="stage == 0" class="col s12 m10 l6 push-m1 push-l3" style="margin-top: -20px;">
                <calendar :calendar="calendar" @select="slotSelected" @refresh="$emit('refresh');"></calendar>
            </div>
            <!-- Contact Information -->
            <div v-if="stage == 1" class="col s12 m10 l6 push-m1 push-l3" style="margin-top: -20px;">
                <patient :template="template" :vaccines="vaccines" @submit="patientSet"></patient>
            </div>
        </div>
    </div>
    <!-- Modal for email confirmation -->
    <div class="modal" ref="modal">
        <div class="modal-content">
            <h4 style="display: flex; align-items: center;"><i class="material-icons orange-text" style="font-size: 35px; margin-top: 5px;">warning</i>Wichtig</h4>
            <p>Wir haben Ihre Terminbestätigung an die angegebene <span class="chip" style="font-weight: bold;">E-Mail Adresse</span>versandt.</p>
            <p>Bitte bestätigen Sie diesen Termin <b>innerhalb der nächsten Stunde</b>, sonst wird Ihre Buchung verworfen.</p>
        </div>
        <div class="modal-footer">
            <button class="modal-action modal-close waves-effect waves-orange btn-flat" @click="modal.close()">
                Schließen
                <i class="material-icons right">close</i>
            </button>
        </div>
    </div>
</template>
<script>
// Components
import calendar from "../calendar";
import patient from "../patient";

// Materialize
import M from "materialize-css";

export default {
    name: "Wizard",
    props: {
        type: {
            type: String,
            required: true
        },
        template: {
            type: Object,
            required: true
        },
        calendar: {
            type: Object,
            required: true
        },
        appointments: {
            type: Array,
            required: true
        },
        vaccines: {
            type: Array,
            required: true
        },
        selected: {
            type: String,
            required: false,
            default: null
        }
    },
    emits: ["appointment", "refresh", "deleteAppointments"],
    components: {
        calendar, patient
    },
    data() {
        return {
            // Objects
            modal: null,
            // States
            wizard: false,
            stage: 0,
            newAppointment: null,
            nearOutdated: false,
            outdated: false,
            interval: null,
        };
    },
    mounted() {
        this.modal = M.Modal.init(this.$refs.modal);

        // Check Constantly if slot is outdated
        this.interval = setInterval(() => {
            this.outdated = this.slotOutdated();
            this.nearOutdated = this.slotNearOutdated();
        }, 100);
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
    methods: {
        slotOutdated() {
            const time = this.newAppointment?.slot ? this.slotToTimestamp(this.getSlot(this.newAppointment.slot)) : null;
            if(!time) return false;
            return this.newAppointment?.slot && time < Date.now() && this.stage > 0;
        },
        slotNearOutdated() {
            const time = this.newAppointment?.slot ? this.slotToTimestamp(this.getSlot(this.newAppointment.slot)) : null;
            if(!time) return false;
            return this.newAppointment?.slot && time < Date.now() + (1000 * 60 * 5) && this.stage > 0;
        },
        createNew() {
            this.newAppointment = Object.assign({}, this.template);
            this.wizard = true;
        },
        slotSelected(slot) {
            this.newAppointment.slot = slot._id;
            this.stage = 1;
        },
        patientSet(patient) {
            // Set Data
            this.newAppointment.patient = patient.patient;
            this.newAppointment.other = patient.other;

            // Show Loader
            this.$loader(true);

            // Submit to DB
            this.$db(`/${this.type}`, { appointments: [this.newAppointment] }, "POST").then(data => {

                // Emit new Appointment
                this.$emit("appointment", data[0]);

                // Hide Loader
                this.$loader(false);

                // Send Notification
                M.toast({ html: "Ihr Termin wurde angelegt.", classes: "green darken-2" });

                // Open E-Mail Confirmation Modal
                this.modal.open();

                // Refresh
                this.$emit("refresh");

                // Reset Wizard
                this.wizard = false;
                this.newAppointment = null;
                this.stage = 0;
            }).catch(err => {
                // Hide Loader
                this.$loader(false);
                M.toast({html: err.error, classes: "red darken-4"});
            });
        },
        getSlot(id) {
            return this.calendar.slots.find(slot => slot._id === id);
        },
        slotToTimestamp(slot) {
            // Get Current Date
            const date = new Date();
            const resultDate = new Date(date.getTime());

            // Set Hour and Minute
            resultDate.setHours(slot.meta.hour);
            resultDate.setMinutes(slot.meta.minute);

            // Remove Milliseconds & Seconds
            resultDate.setSeconds(0);
            resultDate.setMilliseconds(0);

            // Get Next Date
            resultDate.setDate(date.getDate() + (7 + slot.meta.day - date.getDay()) % 7);

            // Return
            return resultDate.getTime();
        },
        resetAppointments($event) {
            $event.preventDefault();
            this.$emit('deleteAppointments');
            M.toast({ html: 'Terminspeicher zurückgesetzt.', classes: "green darken-2" });
        }
    }
}
</script>
<style scoped>
.modal { width: auto !important; max-width: 40% !important;}  /* increase the height and width as you desire */
/** Other with on smaller screens */
@media only screen and (max-width: 1000px) {
    .modal { width: auto !important; max-width: 85% !important; }
}
</style>