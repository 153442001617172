<template>
    <tr v-if="unused > 0 && slotToTimestamp(timeslot) > Date.now()">
        <td>
            <p>
                {{ timeslot.meta.hour.toString().padStart(2, 0) }}:{{ timeslot.meta.minute.toString().padStart(2, 0) }} Uhr
            </p>
        </td>
        <td>
            <div :class="`chip ${unused > 2 ? 'green lighten-3' : 'red lighten-3'}`" style="font-weight: bold;">{{ timeslot.capacity - timeslot.used }} {{ unused !== 1 ? "Plätze" : "Platz" }} <span class="hide-on-small-and-down">verfügbar</span></div>
        </td>
        <td>
            <!-- Big Button -->
            <a href="#" class="btn waves-effect waves-light orange accent-4 hide-on-small-and-down" @click="$emit('selected', timeslot)">
                <i class="material-icons left">check</i>
                <span class="hide-on-small-and-down">Buchen</span>
            </a>
            <!-- Small Button -->
            <a href="#" class="btn-floating waves-effect waves-light orange accent-4 hide-on-med-and-up" @click="$emit('selected', timeslot)">
                <i class="material-icons left">check</i>
            </a>
        </td>
    </tr>
</template>
<script>
export default {
    name: "Cell",
    props: {
        timeslot: {
            type: Object,
            required: true
        }
    },
    emits: ["selected"],
    data() {
        return {
            // States
            unused: this.timeslot.capacity - this.timeslot.used
        };
    },
    methods: {
        slotToTimestamp(slot) {
            // Get Current Date
            const date = new Date();
            const resultDate = new Date(date.getTime());

            // Set Hour and Minute
            resultDate.setHours(slot.meta.hour);
            resultDate.setMinutes(slot.meta.minute);

            // Remove Milliseconds & Seconds
            resultDate.setSeconds(0);
            resultDate.setMilliseconds(0);

            // Get Next Date
            resultDate.setDate(date.getDate() + (7 + slot.meta.day - date.getDay()) % 7);

            // Return
            return resultDate.getTime();
        }
    }
}
</script>
<style scoped>
.table td,tr>td,tr>th {
    text-align: center;
}
</style>