<template>
    <div class="container">
        <div v-if="!started" class="row">
            <!-- About Element -->
            <div class="col s12 m10 l6 push-m1 push-l3">
                <impfung-start v-if="type==='impfung'" @started="started = true; selected = $event;"></impfung-start>
                <test-start v-else @started="started = true"></test-start>
            </div>
        </div>
        <!-- Appointment Element -->
        <appointment-element v-else :type="type" :template="template" :calendar="calendar" :appointments="appointments" :vaccines="vaccines" :selected="selected" @refresh="loadData()" @appointment="addAppointment" @deleteAppointments="deleteAppointments"></appointment-element>
    </div>
</template>
<script>
// Components
import AppointmentElement from './components/appointment/';
import ImpfungStart from './components/start/impfung.vue';
import TestStart from './components/start/test.vue';

export default {
    name: "Appointment",
    components: {
        AppointmentElement,
        ImpfungStart,
        TestStart
    },
    data() {
        return {
            type: "",
            template: {},
            calendar: {},
            vaccines: [],
            appointments: [],

            // Selection
            selected: null,

            // States
            started: false
        };
    },
    watch: {
        $route() {
            this.type = this.$route.params.type;
            // Reload Data
            this.loadData();
        }
    },
    mounted() {
        // Get Type from Params
        this.type = this.$route.params.type;

        // Load Data
        this.loadData();
    },
    methods: {
        async loadData() {
            // Check Type
            if(!this.type.length) {
                return;
            }

            // Show Loading Spinner
            this.$loader();

            // Get Template
            this.template = await this.$db(`/${this.type}/new`);

            // Get Calendar
            this.calendar = await this.$db(`/${this.type}`);

            // Load Vaccines
            this.vaccines = await this.$db("/vaccine");

            // Load appointments from localStorage by type
            if(localStorage.getItem(this.type)) {
                this.appointments = JSON.parse(localStorage.getItem(this.type));
                // Sort Appointments
                this.sortAppointments();
            }

            // Hide Loading Spinner
            this.$loader(false);

            // Calculate Calendar starting from today
            this.sortCalendar();
        },
        addAppointment($event) {
            this.appointments.push($event);

            // Push to localStorage
            localStorage.setItem(this.type, JSON.stringify(this.appointments));

            // Sort Appointments
            this.sortAppointments();
        },
        deleteAppointments() {
            // Get all appointments from localStorage
            let appointments = JSON.parse(localStorage.getItem(this.type)) || [];

            // Filter out every appointment with timestamp < now
            appointments = appointments.filter(appointment => {
                return appointment.timestamp > Date.now();
            });

            // Set appointments to localStorage
            localStorage.setItem(this.type, JSON.stringify(appointments));

            // Set appointments
            this.appointments = appointments;

            // Sort Appointments
            this.sortAppointments();
        },
        sortAppointments() {
            // Sort appointments by timestamp
            this.appointments.sort((a, b) => {
                return a.timestamp - b.timestamp;
            });
        },
        dayOrder() {
            // Return array of days, starting from to day
            let days = [];
            let today = new Date();
            let day = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            for(let i = 0; i < 7; i++) {
                // Push day
                days.push(day.getDay());
                // Increase Day
                day.setDate(day.getDate() + 1);
            }
            return days;
        },
        sortCalendar() {
            // Get day order
            let dayOrder = this.dayOrder();

            this.calendar.slots.sort(function(a, b){
                return dayOrder.indexOf(a.meta.day) - dayOrder.indexOf(b.meta.day);
            });
        }
    }
}
</script>