/**
 * Imports
 */

/**
 * Elements
 */
const spinner = document.createElement('div');
const spinnerBackground = document.createElement('div');

/**
 * States
 */
var isShown = false

/**
 * Module Export
 */
export default (app) => {
    // Init Spinner
    init();

    // Add Function
    app.config.globalProperties.$loader = loader;
}

/**
 * Init
 */
const init = () => {
    spinner.classList.add("preloader-wrapper", "big", "active");
    spinner.innerHTML = `
    <div class="spinner-layer spinner-yellow-only">
        <div class="circle-clipper left">
            <div class="circle"></div>
        </div>
        <div class="gap-patch">
            <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
            <div class="circle"></div>
        </div>
    </div>
    `

    spinnerBackground.append(spinner);
    spinnerBackground.className = 'background';
}

/**
 * Loader Function
 * @param {boolean} show show loader
 */
const loader = (show = true) => {

    // Do nothing if is shown already
    if (show && isShown) return

    // Set State
    isShown = show;

    // Show Loader
    if (show) {
        document.body.appendChild(spinnerBackground);
        return;
    }

    // Hide
    try {
        document.body.removeChild(spinnerBackground);
    } catch (error) {
        /** Ignore */
    }
}
