/**
 * Imports
 */
import axios from "axios";
import { useCookies } from "vue3-cookies";

/**
 * Consts
 */
const BASE_URL = process.env.VUE_APP_API_URL;
const { cookies } = useCookies();

/**
 * Request Function
 */
const request = async (path = "", data = {}, protocol = "GET", refreshToken = true) => {

    // Clearn Protocol
    protocol = protocol.toUpperCase();

    // Headers
    const headers = {
        "Content-Type": "application/json",
        jwt: cookies.get("jwt")
    }

    // Append Token
    if (data instanceof FormData) {
        headers["Content-Type"] = "multipart/form-data";
    }

    // Clean Path
    path = path.trim();
    if (path.startsWith("/")) {
        path = path.substr(1);
    }

    // Create URL
    const URL = `${BASE_URL}/${path}`;

    // Create Options
    const options = {
        method: protocol,
        url: URL,
        data: data,
        params: data,
        headers: headers
    }

    // Remove Options based on Type
    if (protocol !== "GET") {
        delete options.params;
    }

    // Create Promise
    return new Promise((resolve, reject) => {
        // Create Request
        axios(options).then(async response => {
            // Check for new Token
            if (refreshToken)
                //checkToken();

                // receive data
                resolve(response.data.data);
        }).catch(async err => {
            // Catch error
            if (err.response) {
                // Check if data from api is accessible
                if (err.response.data) {
                    reject(err.response.data.data);
                    return;
                }
            }
            reject("Die Abfrage an den Server schlug fehl!");
        });
    });
}


/**
 * Module
 */
export default request;