import { createApp } from 'vue'
import App from './App.vue'
import moment from 'moment'

/**
 * Create App
 */
const app = createApp(App);

/**
 * Moment JS
 */
moment.locale("de");
app.config.globalProperties.$moment = moment;

/**
 * Import Styles
 */
import 'materialize-css/dist/css/materialize.min.css'
import 'material-design-icons/iconfont/material-icons.css'

/**
 * API Interface
 */
import db from "./scripts/database/";
app.config.globalProperties.$db = db;

/**
 * Loading Spinner
 */
import loader from './scripts/loader/index.js'
loader(app);

/**
 * Vue Router
 */
import router from "./scripts/router";
router(app);

/**
 * Mount App
 */
app.mount('#app')