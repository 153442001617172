<template>
    <!-- Verify -->
    <div class="container">
        <div class="row">
            <div class="col s12 m10 l6 push-m1 push-l3">
                <div class="card">
                    <div class="card-content">
                        <span class="card-title">Termin Verifizierung</span>
                        <p :class="`chip ${error ? 'red white-text' : 'green white-text'}`">
                            <b>{{ status }}</b>
                        </p>
                        <div style="margin-top: 10px;" class="divider"></div>
                        <p style="margin-top: 10px;">
                            Sie können diese Seite nun schließen.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            status: "Bitte warten...",
            error: false,
        }
    },
    mounted() {
        // Get Code from params
        const code = this.$route.params.code;

        // Check Code
        if(!code.length) {
            this.status = "Fehler: Kein Code übergeben!";
            return;
        }

        // Check DB
        this.$db("/verify/" + code, {}, "PATCH").then(data => {
            this.status = data;
        }).catch(err => {
            this.error = true;
            this.status = "Fehler: " + err.error;
        });
    }
}
</script>