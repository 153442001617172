<template>
    <!-- Vaccine Editor -->
    <div class="row">
        <div class="col s12 m12 l12">
            <!-- Collapsible -->
            <ul class="collapsible">
                <li>
                    <div class="collapsible-header"><i class="material-icons">colorize</i>Verfügbare Impfstoff</div>
                    <div class="collapsible-body white">
                        <div class="row">
                            <div class="col s12 m8 l8 push-m2 push-l2">
                                <table>
                                    <tbody>
                                        <tr v-for="vaccine in vaccines" :key="vaccine._id">
                                            <td v-text="vaccine.name" />
                                            <!-- Remove Button -->
                                            <td style="display: flex; justify-content: flex-end;">
                                                <button class="btn-small waves-effect waves-light red" @click="deleteVaccine(vaccine._id)">
                                                    <i class="material-icons">delete</i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- Add -->
                                <p style="margin-top: 25px;"><b>Neuen Impfstoff Hinzufügen</b></p>
                                <div class="input-field">
                                    <input id="name" type="text" v-model="newVaccineName">
                                    <label for="name">Name</label>
                                </div>
                                <!-- Submit -->
                                <div class="card-action" style="display: flex; justify-content: flex-end;">
                                    <a href="#" class="btn-flat waves-effect waves-light orange-text text-accent-4" @click="add">
                                        <i class="material-icons left">add</i>
                                        Hinzufügen
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import M from "materialize-css";

export default {
    emits: ["refresh"],
    props: {
        vaccines: {
            type: Array,
            required: true
        },
        jwt: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            newVaccineName: ""
        }
    },
    methods: {
        deleteVaccine(id) {
            this.$loader();
            this.$db("/vaccine", {id: id, token: this.jwt}, "DELETE").then(() => {
                this.$emit("refresh");
                this.$loader(false);
            }).catch(err => {
                M.toast({ html: err.error, classes: "red darken-4" });
                this.$loader(false);
            });
        },
        add() {
            if(!this.newVaccineName.length) return;
            this.$loader();
            this.$db("/vaccine", {name: this.newVaccineName, token: this.jwt}, "POST").then(() => {
                this.newVaccineName = "";
                this.$emit("refresh");
                this.$loader(false);
            }).catch(err => {
                M.toast({ html: err.error, classes: "red darken-4" });
                this.$loader(false);
            });
        }
    }
}
</script>